
.priceDetails{
    background-color: var(--blue);
    padding: 2px 20px;
    border-radius: 20px;
    font-size: 20px;
    margin-left: 12px;
}


.imageThumbs{
    width: 100px;
    height:90px;
    background-color: rgb(67, 67, 67);
    margin: 10px 0px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}
.imageThumbs > img{
    object-fit: cover;
    width: 100px;
    height:90px;
}

.tourContainer{
    background-color: var(--lightblue2);
    border-radius: 20px;   
}
.modal{
    display: block;
    position: fixed !important;
    top:0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 999999999999;
}
