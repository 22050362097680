.box-wrp-big{
    width: 60px;
    height:60px;
    border-radius: 8px;
    border:solid 1px var(--blue);
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: var(--blue);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.box-wrp-sm{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border:solid 1px var(--blue);
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: var(--blue);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
}