.image-section{
    height: 439px;
    background-color: #999;
    position: relative;
}
.back-drop-img{
    height: auto;  
    width: 100%; 
    object-fit: fill;
    position: absolute;
}