.AdsCard{
   height: 200px;
   width: 100%;
   background-color: darkgrey;
   display:-webkit-inline-box;
   align-items: center;
   padding: 0px;
   overflow: hidden;
   position: relative;
   /* overflow: scroll; */
}

.ads-container{
    display: flex;
    align-items: center;
    height: 100%;
}
.ads-item{
    width: 100%;
    height: 100%;
    list-style: none;
    cursor: pointer;
}
.ads-item img{
    width: 100%;
    height: 100%;
object-fit: fill;
cursor: pointer;
}
.ads-dots-container{
    position: absolute;
    bottom: 5px;
    left: 5px;
    min-height: 10px;
    background-color: #dddddd8d;
    min-width:10px;
    border-radius: 20px;
    display: flex;
    padding: 2px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px 5px;

}
.ads-dots-container div{
    width:10px;
    height:10px;
    background-color:#444;
    border-radius: 20px;
    cursor: pointer;
}

.ads-title{
    background-color:#444;
    color: white;
    position: absolute;  
    top: 5px;
    right: 5px;
    padding: 2px 5px;
    font-size: 8px;
    border-radius: 5px;
}