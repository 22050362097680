.chat-container{
    float: right;
    position: fixed;
    bottom: 50px;
    z-index: 999;
    right:20px;
    overflow: hidden;
    width: 400px;
}
.chat-inner{
    width: 360px;
    background-color: white;
    height: 400px;
    border-radius: 20px 20px 5px 5px;
    overflow: hidden;
    margin-right: 43px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
}
.chat-icon{
    margin-top: -10px;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    background-color: red;
    float: right;
}
.chat-header{
    height: 10%;
    padding: 8px 20px;
    border-bottom: solid 0.5px #ddd;
    font-weight: 800;
}
.chat-body{
    height: 76%;
    background-color: rgb(246, 246, 246);
    padding: 20px;
    overflow: scroll;
    font-size: 12px;
}
.chat-input-wrapper{
height: 14%;
background-color: white;
padding: 10px;
display: flex;
align-items: center;
border-top: solid 0.5px #ddd;
}
.chat-input-wrapper > textarea{
height: 100%;
width: 100%;
border: 0.5px; 
border-radius: 10px;
outline: none;
resize: none;
background-color: rgba(164, 164, 164, 0.196);
padding-left: 10px;
padding-right: 10px;
padding-top: 5px;
padding-top: 5px;
padding-bottom: 5px;
font-size: 14px;
}
.chat-foot-note{
  padding-top: 5px;
  margin: 5px;
  border-top: solid 0.5px #ddd;
  font-size: 10px;
}
.send-btn{
 width: 35px;   
 height: 30px;
 border: 0px;  
 background-color: transparent; 
}
.speech {
    font-size: 1.05em;
    color: #000000;
    background: #ffffff;
    padding: 5px 20px;
    border-radius: 10px;
    max-width: 500px;
  }
  
  /* (B) USE ::AFTER TO CREATE THE "CALLOUT" */
  .speech::after {
    display: block; width: 0; content: "";
    border: 15px solid transparent;
  }
   
  /* (C) "CALLOUT" DIRECTIONS */
  .speech.up::after {
    border-bottom-color: #ffffff;
    border-top: 0;
  }
  .speech.down::after {
    border-top-color: #ffffff;
    border-bottom: 0;
  }
  .speech.left::after {
    border-right-color: #ffffff;
    border-left: 0;
  }
  .speech.right::after {
    border-left-color: #ffffff;
    border-right: 0;
  }
  
  /* (D) POSITION THE CALLOUT */
  .speech {
    position: relative;
    margin: 30px;
  }
  .speech::after { position: absolute; }
  .speech.up::after {
    top: -15px; left: calc(50% - 15px);
  }
  .speech.down::after {
    bottom: -15px; left: calc(50% - 15px);
  }
  .speech.left::after {
    left: -12.5px; 
    top: 12px;
    transform: rotate(353deg);
  }
  .speech.right::after {
    right: -15px; 
    top: 12px;
  }
  
  #cbwrap{background:rgba(255,255,255,.9);width:600px;padding:30px;border-radius:10px}
  *{box-sizing:border-box;font-family:arial,sans-serif}
  #cbtitle{margin:0 0 30px;padding:0;text-transform:uppercase}
  #cbinfo{margin-top:30px;padding-top:15px;border-top:1px solid #ddd;font-size:13px;font-weight:700}
  #cbinfo a{text-decoration:none;padding:5px;color:#fff;background:#ffffff}