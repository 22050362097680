.menudropdown .dropdown-item.active{
    background-color: rgb(255, 255, 255);
    color: black;
}
.menudropdown .dropdown-item{
width: 50px;
}
.dashboard-button-container{
  border-radius: 40px;
  border:solid 1px var(--blue);
  
}
.dashboard-button-container .btn{
    font-size:12px ;
    color: var(--blue);
}