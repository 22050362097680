.sliderContainer{
    height: 205px;
    width: 460px;
    background-color: rgb(204, 204, 204);
    position: relative;
    display: inline;
}
.sliderImage{
    height: 205px;
    display: -webkit-box;
    position: absolute;
    top: 0px;
    left: 0px;
}
.overflow-radius-10{
    overflow: hidden;
    border-radius:10px;
}
.slider-indicator{
    position: absolute;
    bottom: 10px;
    right:10px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    background-color: #29282869;
    padding: 3px;
    border-radius: 10px;
}
.slider-icon{
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cursor{
    cursor: pointer !important;
}
.imageItem{
    position: relative;
    width: 460px;
    height: 100%;
    overflow: hidden;
}
.loaderIndicator{
    position: absolute;
    margin: auto;
    top:0px;
    left:0px;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    padding-top: 18%;
}
.imageItem > img{
object-fit: cover;
height: 100%;
width: 100%;
}
.slider-indicator > div {
    padding: 2px;
    border-radius: 10px;
    background-color: aliceblue;
    width: 15px;
    height: 15px;
    box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.53);
    -webkit-box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.53);
    -moz-box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.53);
}
.activeDot{
    background-color: rgb(3, 129, 240) !important;
}
.inactiveDot{
    background-color: aliceblue;  
}
.slider-icon-wrpper{
    position: absolute;
    right:10px;
    top:10px;
    background-color: #ffffffc9;
    padding: 3px;
    border-radius: 5px;
}
.add-more-icon{
    position: absolute;
    left:0px;
    bottom: 0px;
    cursor: pointer;
    height: 28px;
    padding: 0px 10px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffffc9;
    border-top-right-radius: 19px;
}