.filter-container{
    padding: 10px;
    height: 40px;
    color: #ddd;
    word-wrap: break-word;
    background-color: white;
    background-clip: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.filter-wrp{
 display: inline-flex;
 margin: 5px;
 flex-direction: column;
 position: relative;
}
.filter-wrp-popup{
    width: 180px;
    height: 80px;
    background-color: red;
    position: absolute;
    top: 41px;
    z-index: 999;
}