.image-thumbnails-container{
    width: 150px;
    min-height: 150px;
    max-height: 430px;
    background-color: var(--light-opacity70);
    position: absolute;
    top:80px;
    right: 50px;
    border-radius: 20px;
}
.image-thumbnails-card{
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.comment-bubble{
    background-color: var(--light-opacity50);
    border-top-right-radius:20px;
    border-bottom-right-radius:20px;
    border-bottom-left-radius:20px;
    border-top-left-radius:0px;
    padding: 10px;
    box-shadow: #ddd 1px 1px 1px 0px;
}
.company-logo{
    width: 100px;
    height: 100px;
    border-radius: 20px; 
    background-color: #444;
    border: solid 2px var(--blue);
    overflow: hidden;
}
.company-logo img{
    width: 100px;
    height: 100px;
    object-fit: fill;
}