.pagination-container{
display: flex;
justify-content: flex-end;
align-items: center;
}
.pagination-button-wrapper{
    border-radius:20px;
    border: solid 1px var(--blue);
    width: 230px;
    display: inline-flex ;
    justify-content: flex-end;
    align-items: center;
    height: 35px;
}
.pagination-button,.pagination-button:disabled,.pagination-button:hover{
cursor: pointer;
height: 100%;
padding: 0px;
border-radius: 0px;
font-size: 12px;
display: flex;
justify-content: center;
align-items: center;
width: 33%;
outline: 0px;
border-width: 0px;
background-color: white;
}
.pagination-button-prev,.pagination-button-prev:disabled,.pagination-button-prev:hover{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    font-weight: bold;
}
.pagination-button-next,.pagination-button-next:disabled,.pagination-button-next:hover{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: bold;
}

.pagination-inactive{
    cursor: none;
    outline: 0px;
    border-width: 0px;
    color: rgba(0, 0, 0, 0.147) !important;
}
.pagination-seperator{
    height:70%;
    background-color:var(--blue);
    width: 1px;
}