.search-section-main{
    position: absolute;
    top:30%;
    left: 5%;
}
.search-section-container {
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    padding: 15px;
    text-align: center;
}
.search-section-inner{
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 20px;
}
.input-search-wrapper{
    border-radius: 20px;  
    border:solid 1px var(--blue);
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    padding-left: 10px;
}
.input-search-wrapper input{
    border-width: 0px;
}
.list-item{
    height: 310px;
    overflow: hidden;
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
}
.h-list-item {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;   
}
@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(50px);
              transform: translateY(50px);
              opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
              opacity: 1;
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(50px);
       transform: translateY(50px);
       opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
              opacity: 1;
    }
  }
  

.list-item:hover{
    transform: scale(1.5);
}

.list-item > a > .card-img-top{
    height: 180px;
    object-fit: cover;
}
.list-item > .card-body > a{
    color: black;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}
.list-item > .card-body > .address{
    color: grey;
    font-weight: normal;
    font-size: 14px;
}
.list-item > .card-body > .type{
    color: black;
    font-weight: normal;
}
.list-item > .card-body > a:hover{
    color: var(--blue);
}

