:root {
  --blue:#039BE5;
  --lightblue:#a9e2ff;
  --trendy:#be9722;
  --lightblue2:#E7F6FD;
  --light-opacity70:#a9e2ff70;
  --light-opacity50:#a9e2ff32;
  }
  body{
    -webkit-text-size-adjust:none;
    -ms-text-size-adjust:none;
    -moz-text-size-adjust:none;
    text-size-adjust:none;
  }
  .filterWraper{
    min-width: 100px;
    border-radius: 20px 0px 0px 20px;
    border:solid 1px var(--blue);
    display: flex;
    align-items: center;
    padding: 1px 10px;
    overflow: hidden;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
  }
  .btn:hover{
    background-color: white;
  }
  .blueOutlineBtn{
      font-family: "DM Sans", Sans-serif;
      font-size: 12px;
      font-weight: bold;
      background-color: #ffffff;
      border-style: solid;
      color: var(--blue);
      border-width: 2px 2px 2px 2px;
      border-color: var(--blue);
      border-radius: 30px 30px 30px 30px;
      padding: 5px 23px 5px 23px;
      margin: 0px 5px;
  }
  .icon-wrp{
    width: 40px;
    height: 40px;
    background-color: #d7d9d6;
    border-radius: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-wrp-success{
    background-color: #c7ffc2;
  }
  .icon-wrp-danger{
    background-color: #ffc2c2;
  }
  .icon-wrp-positive{
    background-color: #c2d9ff;
  }
  .icon-wrp-info{
    background-color: #feffc2;
  }
  .inactiveBtn{
    font-family: "DM Sans", Sans-serif;
    font-size: 12px;
    font-weight: bold;
    background-color:#9b9b9b;
    border-style: solid;
    color: #ffffff;
    margin: 0px 5px;
    border-width: 2px 2px 2px 2px;
    border-color: #9b9b9b;
    border-radius: 30px 30px 30px 30px;
    padding: 8px 23px 8px 23px;
    height: 45px;
  }
  .blueBtn{
      font-family: "DM Sans", Sans-serif;
      font-size: 12px;
      font-weight: bold;
      background-color:var(--blue) !important;
      border-style: solid;
      color: #ffffff;
      margin: 0px 5px;
      border-color: var(--blue);
      border-radius: 30px 30px 30px 30px;
      padding: 8px 23px 8px 23px;
      height: 45px;
      cursor: pointer;
      border:solid 3px rgb(184 231 255 / 71%);
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .blueBtn:hover{
      font-family: "DM Sans", Sans-serif;
      background-color: #ffffff !important;
      border-color: var(--blue);
      border-radius: 30px 30px 30px 30px;
      color: var(--blue) !important;
      height: 45px;
  }
  .blueOutlineBtn:hover{
      background-color: var(--blue);
      border-color: #ffffff;
      color: #ffffff;
  }
  .nav-item{
    padding: 0px 20px; 
    border-right:solid 0.5px var(--blue);
    color: var(--blue);
  }
  .nav-item a{
      color: var(--blue);
    }
    .nav-item a:hover{
      color: #000;
    }
   .bg-cover{
      min-height: 600px;
   }
   .blueTitle{
      color: var(--blue);
      font-size: 2.5em;
   }
   .dropdownItem{
      font-size: 14px;
      cursor: pointer;
      position: relative;
      width: 100%;
   }
   .dropdownItemCaret{
     background-color: var(--lightblue);
     width: 20px;
     height: 20px;
     float: right;
     border-radius: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
     cursor: pointer;
   }
   .dropdown-item{
      cursor: pointer;
   }
   .dropdown-menu{
      max-height: 238px;
      overflow: scroll;
   }
   .float-right{
      float: right;
   }
   .blueBtnSm{
      background-color: var(--blue);
      padding: 2px 10px;
      border-radius: 20px;
      font-size: 12px;
      color: white;
      margin: 0px 5px;
   }
   .whiteBtnSm{
      background-color:white;
      padding: 2px 10px;
      border-radius: 20px;
      font-size: 12px;
      color: var(--blue);
      margin: 0px 5px;
   }
   .redBtnSm{
      background-color:red;
      padding: 2px 10px;
      border-radius: 20px;
      font-size: 12px;
      color: white;;
      margin: 0px 5px;
   }
   .yellowBtnSm{
      background-color:var(--trendy);
      padding: 2px 10px;
      border-radius: 20px;
      font-size: 12px;
      color: white;;
      margin: 0px 5px;
   }
   .wishlistBtn{
      position: absolute;
      top: 130px;
      right: 60px;
      background-color: #ffffff;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
   }
   .shimmerAnimation {
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    background-size: 300%;
    background-position-x: 100%;
    animation: shimmer 1s infinite linear;
   }
   @keyframes shimmer {
    to {
       background-position-x: 0%
    }
  }
   .compareBtn{
      position: absolute;
      top: 130px;
      right: 10px;
      background-color: #ffffff;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.145),0px 0.5px 3px rgba(0, 0, 0, 0.145) !important;
  -moz-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.145),0px 0.5px 3px rgba(0, 0, 0, 0.145) !important;
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.145),0px 0.5px 3px rgba(0, 0, 0, 0.145)!important;
  
   }
   .compareBtn:hover,.wishlistBtn:hover{
      background-color: var(--lightblue);
   }
   .smIcon{
      width: 20px;
      height: 20px;
   }
   .amenitiesIcons{
     padding: 0px 5px; 
     font-size: 12px;
     float: right;
   }
   .blueSection{
      background-color: var(--lightblue2);
   }
   .homeSliderImg{
      width: 100%;
      height: 450px; 
      border-radius: 10px; 
      background-color: #434343;
   }
   .carousel-control-next, .carousel-control-prev {
    top: 45% !important;
    width:40px !important;
    height:40px !important;
    border-radius: 40px;
    background-color: var(--blue);
    padding: 10px;
   }
   .amenitiesIconsLarge{
      background-color: white;
      padding: 10px;
      height: 60px;
      border-radius: 10px;
      display: inline-flex;
      justify-items: center;
      align-items: center;
      box-shadow: solid 1px 1px 1px #000;
      font-size: 16px;
      min-width: 120px;
      margin: 0px 10px;
   }
   .address{
      color: gray;
   }
   .priceLarge{
      position: absolute;
      top:20px;
      right:20px;
      font-size: 20px;
      font-weight: bold;
      background-color: white;
      padding: 1px 20px;
      border-radius: 20px;
      color: var(--blue);
   }
   .position-relative{
      position: relative;
   }
   .item-details a{
   text-decoration: none;
   color: var(--blue);
   }
   .dropdown-menu{
      inset: 0px auto auto 80px !important;
   }
   .white-text{
      color: #ffffff !important;
   }
   .react-datepicker__input-container > input {
    border: 0px;
   }
   .roundTextarea{
    background-color: white; 
    border-radius:8px;
    min-height: 100px;
    margin: 0px;
    border: solid 1px var(--lightblue);
    width: 100%;
    overflow: hidden;
   }
   .roundInput,.left-icon-wrap,.react-datepicker__input-container{
     background-color: white; 
     border-radius: 50px;
     height: 40px;
     margin: 10px 0px;
     border: solid 1px var(--lightblue);
     width: 100%;
     overflow: hidden;
     display: flex;
     justify-content: center;
     align-items: center;
   }
   .react-datepicker__input-container{
    padding:5px 15px;
    outline: 0px;
   }
   .navbar-fixed{
     position: fixed;
     width: 100%;
     z-index: 9999;
     top: 0px;
     left: 0px;
     right:0px;
   }
   main{
     padding-top: 75px;
   }
   #map-container{
     height: 100%;
     width: 100%;
     background-color: #343232;
   }
   .baseFormControl{
     border-radius: 60px;
     padding: 8px 20px;
   }
   .left-icon-wrap{
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 0px;
   }
   .left-icon-wrap > .left-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d6d6d6;
    padding: 8px 20px;
   }
   .left-icon-wrap > input{
  border:0px ;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline:0px !important;
  width: 100% !important;
   }
   .roundInput select{
    outline:0px !important;
    width: 92% !important;
    border:0px ;
   }
   .pageTitle{
     padding: 3px 20px 3px 20px;
     margin-bottom: 0px;
     background-color: #1DB2FF;
     display:inline-block ;
     color: #ffffff;
     border-radius: 10px 10px 0px 0px;
     font-size: 16px;
     font-weight: bold;
     text-align: center;
   }
   .agentAvatar{
     width: 100px;
     height: 100px;
     background-color: #343232;
     border-radius: 100%;
   }
   .agentTitle{
     font-size: 16px;
     color: #6d6d6d;
     font-weight: bold;
   }
   .titleText{
    font-size: 16px;
    color: #000000;
    font-weight: bold;
  }
   .agentName{
     font-size:22px;
     color: #343232;
     font-weight: bold; 
   }
   .agentMore{
     font-size:13px;
     font-weight: bold;
   }
   .agentMore a{
     font-size:16px;
     text-decoration: none; 
     color: var(--blue);
   }
   .tourSection{
     border:solid 1px var(--blue);
     border-top-right-radius: 20px;
     padding: 30px;
   }
   table .t1{
     font-size:14px;
     color: #6d6d6d;
     font-weight: bold;
     text-align: end;
   }
   table .t2{
     font-size:14px;
     color: #000000;
   }
   .roundTextarea{
     background-color: white; 
     border-radius: 15px;
     margin:0px;
     border: solid 1px var(--lightblue);
   }
   input,textarea{
    margin:0px !important;
   }
   .progress{
    width: 100%;
    height:6px;
    padding-left:2px;
    padding-right:2px;
   }
   .lx .form-check-input,.ly .form-check-input{
    border-radius: 20px !important;
    width: 25px;
    height: 25px;
    margin-top: 10px;
    cursor: pointer;
    border: solid 1px var(--lightblue);
   }
   .lx,.ly{
    position: relative;
   }
   .ly > .mb-3 > .form-check > img{
    margin-top:0px !important;
   }
   .ly > .mb-3 > .form-check > .form-check-label{
    margin-top:-8px !important;
   }
   .lx .form-check-label{
    top:35px;
    position: absolute;
    font-size: 12px;
    color: #00646f94;
   }
   .ly .form-check-label{
    top:13px;
    position: absolute;
    font-size: 12px;
    color: #00646f94;
   }
   .avatar,.avatar img{
     width: 40px;
     height: 40px;
     border-radius: 100%;
     background-color: #454545;
     overflow: hidden;
   }
   .avatar img{
     width:  100% !important;
     height:  100% !important;
   }
   .hide-arrow::after{
     content: none !important;
   }
   .sidebar {
     margin: 0;
     padding: 0;
     background-color: #f1f1f1;
     height: 100%;
     overflow: auto;
   }
   .bashboard-main{
    flex-grow: 1;
   }
   /* Sidebar links */
   .sidebar div {
     display: block;
     color: black;
     padding: 12px;
     font-size: 14px;
     max-width: 173px;
     text-decoration: none;
     cursor: pointer;
   }
  
   /* Active/current link */
   .sidebar-active {
     background-color: var(--blue);
     color: white;
     font-size: 14px;
   }
   
   /* Links on mouse-over */
   .sidebar div:hover:not(.active) {
     background-color: #555;
     color: white !important;
     font-size: 14px;
   }
   .avatar{
    overflow: hidden !important;
   }
   .avatar img{
    width: 100%;
    height: 100%;
    border: none;
    border-radius:100%;
   }
   .btn:active {
    border: none !important;
   }
   .icon-w{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    background-color: #21883735;
    align-items: center;
    justify-content: center;
   }
   .icon-w-succes{
    background-color: #03c92e43;
   }
   .icon-w-danger{
    background-color: #c9030343;
   }
   .icon-w-info{
    background-color: #c9bc0343;
   }
   .sidebar-hover {
    background-color: #555;
    color: white;
    font-size: 14px;
  }
   
   /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
   div.content {
     margin-left: 200px;
     padding: 1px 16px;
     height: 1000px;
   }
   
   /* On screens that are less than 700px wide, make the sidebar into a topbar */
   @media screen and (max-width: 700px) {
     .sidebar {
       width: 100%;
       height: auto;
       position: relative;
     }
     .sidebar a {float: left;}
     div.content {margin-left: 0;}
   }
   
   /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
   @media screen and (max-width: 400px) {
     .sidebar a {
       text-align: center;
       float: none;
     }
   }
   /*  search section home screen */
   .sidebar-tabs{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left:20px;
   }
   .lighblue-background{
    background-color: var(--lightblue2);
   }
   .big-avatar{
    width:100px;
    height:100px;
    border-radius: 100%;
    background-color: #4f4f4f;
    overflow: hidden;
    align-self: center;
   }
   .error{
    color: red;
    font-size: 12px;
    margin-left: 10px;
   }
   label{
    font-size: 13px;
    color: rgb(57, 57, 57);
    font-weight: 600;
    margin-left: 10px;
   }
   
   .blockIcon{
    position: absolute;
    top: 30px;
    right: 10px;
   }
   .nav-link{
    color: var(--blue);
   }
   .thumbnail-list{
    width:80px;
    height:60px;
    background-color: #7e7e7e;
    border-radius: 5px;
   }
   .table-container{
    height: 100vh;
    overflow: scroll;
   }
  .eyeIcon{
    position: absolute;
    top: 7px;
    right: 14px;
    cursor: pointer;
  }
  .timerText{
    font-weight: bold;
  }
  .disableBtn{
    font-family: "DM Sans", Sans-serif;
    font-size: 12px;
    font-weight: bold;
    background-color:rgb(183, 183, 183) !important;
    border-style: solid;
    color: #ffffff;
    margin: 0px 5px;
    border-width: 2px 2px 2px 2px;
    border-color: gray !important;
    border-radius: 30px 30px 30px 30px;
    padding: 5px 23px 5px 23px;
  }
  .timerText_{
  text-decoration: underline;
  cursor: pointer;
  }
  .h-thumbnail{
    background-color: #555555;
  }
  .fs-7{
    font-size: 0.8rem!important;
    color: var(--blue);
  }
  .icon-w > img {
    filter: sepia(10%);
  }
  .verified{
    width: 85px;
    border-radius: 30px;
    border: solid 2px limegreen;
    display: flex;
    align-items: center;
  }
  
  .verifiedText{
    color: limegreen;
    font-size: 12px;
    padding: 2px;
  }
  .UnVerified{
    width: 90px;
    border-radius: 30px;
    border: solid 2px red;
    display: flex;
    align-items: center;
  }
  
  .UnVerifiedText{
    color: red;
    font-size: 12px;
    padding: 2px;
  }
  .tour-error{
    margin-top: -17px;
    display: inherit;
    color: red;
  
  }
  .inline-container{
    display: -webkit-inline-box;
    overflow: scroll;
  }
  .slider1,.carousel-item{
    height: 400px;
  }
  .images{
    border-radius: 10px;
    border: solid 2px var(--blue);
    width: 100px;
    height: 70px;
    position: absolute;
    bottom: 20px;
    left:40px;
    overflow: hidden;
    background-color: #343232;
    color: var(--blue);
    font-size: 12px;
  }
  .images > img{
    width: 100px;
    height: 70px;
  }
  .images > .wpp{
    width: 100px;
    height: 70px;
    position: absolute;
    top: 0px;
    left:0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.51);
    cursor: pointer;
  }
  .imagePreviewContainer{
    height: 90vh;
    overflow: hidden;
    position: relative;
    
  }
  .imagePreviewContainerInner > img{
    width:100%;
    height:100%;
    object-fit:fill;
    background-color: #2d2d2d;
  }
  .imagePreviewThumnailContainer{
    height: 90px;
    position: absolute;
    bottom: 30px;
    right:0px;
    overflow: hidden;
    display: inline-flex;
    background-color: #00000087;
    padding: 10px;
  
  }
  .divWp{
    height: 70px;
    width: 120px;
    margin-left:5px;
    border-radius: 8px;
    border:solid 2px var(--blue);
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }
  .divWp > img{
    height: 70px;
    width: 120px;
    cursor: pointer;
   
  }
  .imagePreviewContainer > .close{
    position: absolute;
    top:10px;
    right:10px;
    cursor: pointer;
  }
  .check{
    position: absolute;
    top:2px;
    right:5px;
  }
  
  .dropdown-container{
    position: absolute;
    background-color: white;
    z-index: 9999;
    display: block;
    max-height: 200px;
    overflow: scroll;
    right:0px;
    top: 0px;
    padding: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.49);
    -webkit-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.49);
    -moz-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.49);
  }
  .dropdown-container > li,.dropdown-item{
    cursor: pointer;
    min-width: 150px !important;
    list-style: none !important;
    padding: 8px 16px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.11);
  }
  .roundInput input {
    width: 90%;
    border: 0px;
    outline: 0px;
    color: #555;
  }
  .thumbImage{
    height:80px;
    width:100%;
  }
  .ImageEditorContainer{
    position: relative;
    overflow: hidden;
  }
  .ImageEditor{
    height:125%;
    width:105%; 
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.521);
    position: absolute;
    top: -20px;
    left: -10px;
    right:0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ImageEditor .card{
    height:60%;
    width:85%;
    margin-top: -40px;
  }
  .fileInput{
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 999;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
  }
  .blueText{
    color: var(--blue);
  }
  .fileInputWrapper{
    overflow: hidden;
    border-radius: 8px;
  }
  .fileInputWrapper:hover{
    background-color: #cbcbcb;
    cursor: pointer;
  }
  
  .photo-editor-main{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: #00000073;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    height: 100%;
    width: 100%;
  }
  .bg-white{
    border-radius: 8px;
    margin-top: 30px;
    width: 40%;
    position: relative;
  }
  .canvas{
    height: auto;
    width: 100%;
    object-fit: fill;
  }
  .rounded-t{
    position: relative;
  }
  .rounded-t{
   position: absolute;
    top:0px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rounded-t > button{
  background-color: var(--blue) !important;
  color: white;
  font-size: 12px;
  margin-left: 10px;
  }
  .w-14{
    width: 50px;
      border: solid 0.5px #999;
      border-radius: 5px;
      align-content: center;
      justify-content: center;
      text-align: center;
  }
  input[type="range"]{
      width: 60%;
      margin-left: 10px;
      margin-right: 10px;
  }
  .flex-row{
      display: flex;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
  }
  .mb-1{
    display: flex;
    justify-content: flex-start;
    align-items:center;
  }
  .mx-1{
    border: none;
  }
  .form-label{
    margin-bottom: -5px;
  }
  .right-icon{
    position: absolute;
    right:0px;
    top:42px;
    background-color: red;
  }
  .roundInput .right-icon{
    right:10px;
    top:5px;
    cursor: pointer;
  }
  .card a{
    color: var(--blue) !important;
  }
  .hideElement{
    display: none !important;
  }
  .fs-8{
    font-size: 0.7rem!important;
  }
  .post-comment-button{
    cursor: pointer;
    padding: 4px 10px;
    border-radius:10px;
    background-color: #1DB2FF;
    color: white;
    border: 0px;
    float: right;
    font-size: 0.8rem;
  }
  .light-blue{
    color: var(--lightblue);
  }
  .typeView,.typeView:hover{
      padding: 5px 10px 2px 10px;
      left: 0px;
      background-color: var(--blue);
      border-radius: 0px 0px 15px 0px;
      top: 0px;
      color: white;
      font-size: 12px;
      margin-top: -11px;
      margin-left: -11px;
  }
  .btn-default{
    border-width: 0px !important;
    outline: 0px !important;
    cursor: pointer;
  }
  .notificationContainer{
    display: flex;
  }
  .notificationCounter{
    font-size: 9px;
    color: red;
    position: absolute;
  }
  .dropdown-item{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .dropdown-item .icon{
    width: 15px;
    height: 15px;
    display: flex;
    margin-right:5px;
  }
  .icon-container{
    position: absolute;
    bottom: 130px; 
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right:15px;
  }
  .icon-container .Icons{
    background-color: #ffffff;
    margin-left: 18px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.145),0px 0.5px 3px rgba(0, 0, 0, 0.145) !important;
  -moz-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.145),0px 0.5px 3px rgba(0, 0, 0, 0.145) !important;
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.145),0px 0.5px 3px rgba(0, 0, 0, 0.145)!important;
  }
  .virtualIcon{
    position: absolute;
    top: 130px;
    right: 50px;
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.145),0px 0.5px 3px rgba(0, 0, 0, 0.145) !important;
  -moz-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.145),0px 0.5px 3px rgba(0, 0, 0, 0.145) !important;
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.145),0px 0.5px 3px rgba(0, 0, 0, 0.145)!important;
  
  }
  .ytp-chrome-top, .ytp-show-cards-title, .ytp-impression-link{
    display: none !important;
  }
  .Toastify{
    position: relative !important;
    z-index: 99999999999 !important;
  }
  .tips{
    position: absolute;
    bottom: -15px;
    color: white;
    font-size: 8px;
    width: 50px;
    align-self: center;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.698);
  }
  .t1,.t2{
    text-shadow: 0.5px 0.5px 0.5px rgba(255, 255, 255, 1);
  }
  .form-check{
    padding-left: 0px !important;
  }
  input[type="checkbox"],input[type="radio"]{
    border-radius: 20px !important;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border: solid 1px var(--lightblue);
  }
  .form-check-label-small {
  font-size: 12px;
  color: #666666 !important;
  font-style: italic;
  }
  .list-group-item .form-check{
    margin-left: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
  }
  .otp input{
    border-radius: 5px !important;
    width: 60px;
    margin-left:5px !important;
    margin-right:5px !important;
    padding: 0px !important;
    text-align: center !important;
  }
  .flagContainer{
    min-width: 100px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: rgb(237, 250, 255);
    border-right: solid #ddd 1px;
    padding-left: 10px;
  }
  .flagContainer img{
    width: 30px;
    height: 20px;
    background-color: rgb(88, 88, 88);
    border-radius: 3px;
  }
  .flagContainer span{
  font-weight: 500;
  }
  .whatsapp-icon{
    position: absolute;
    right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #737373;
  }
  .whatsapp-checker{
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: solid #ddd 1px;
    margin-left:5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .check-box{
    display: inline-flex !important;
    justify-content: flex-start;
    align-items: center;
    text-wrap: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .map-marker{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  #map{
    height: 100%;
    width: 100%;
  }
  .btn-wrapper{
    height: 40px;
    width: 200px;
    border-radius: 40px;
    border:solid 1px var(--blue);
    overflow: hidden;
    color: #000000 ;
    cursor: pointer;
  }
  .tab-btn{
    width: 50%;
    height: 100%;
    text-align: center;
    color: #000;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .tab-btn-active{
    background-color: var(--blue);
    color: white !important;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .center{
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .editorContainer{
    height:400px;
  }
  .box-shadow{
    box-shadow: 0.5px 0.5px 1px 0.5px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0.5px 0.5px 1px 0.5px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0.5px 0.5px 1px 0.5px rgb(0 0 0 / 20%);
  }
  .btn-fill{
    width: 100% !important;
  }
  
        .container_ {
            text-align: center;
        }
        .h1 {
            font-size: 3em;
            color: #333;
        }
        .p {
            font-size: 1.2em;
            color: #666;
        }
        .email-signup {
            margin-top: 20px;
        }
        
        input[type="submit"] {
            padding: 10px 20px;
            font-size: 1em;
            background-color: #28a745;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
        input[type="submit"]:hover {
            background-color: #218838;
        }
        .appName{
          font-size: 20px;
          margin-left:5px;
          color: #162a5e;
          font-weight: bold;
        }
        .appName-white{
          font-size: 20px;
          color: white;
          margin-left:5px;
          font-weight: bold;
        }
        .imageGalleryContainer{
          height: 600px;
          background-color: #464646;
          position: relative;
          overflow: hidden;
      }
      .imageGalleryBlind{
        position: absolute;
        bottom: 0px;
        left: 0px;
        min-height: 150px;
        width: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.7));
    }
    .imageThumbsContainner{
      width: 230px;
      height:460px;
      position: absolute;
      top:0px;
      right:50px;
      z-index: 999;
  }
  .imageThumbsContainner > .inner{
    overflow-x:scroll;
    width: 230px;
    height:460px;
  }
  .imageThumbs{
      width: 100px;
      height:90px;
      background-color: rgb(67, 67, 67);
      margin: 10px 0px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
  }
  
  .imageThumbs > img{
      object-fit: cover;
      width: 100px;
      height:90px;
  }
  .imageBigThumbs{
    border:solid  5px white !important;
    width: 220px;
    height:160px;
    background-color: rgb(67, 67, 67);
    margin: 10px 0px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}
  .imageBigThumbsActive, .imageBigThumbs:hover{
    border:solid  5px var(--blue) !important;
    width: 220px;
    height:160px;
    background-color: rgb(67, 67, 67);
    margin: 10px 0px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}
.imageBigThumbs > img,.imageBigThumbsActive > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.iconsContainer{
  cursor: pointer;
  background-color: #343434;
  width:40px;
  height:40px;
  border-radius: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconsContainer:hover{
  background-color: var(--blue);
}
.lightBlue{
  background-color: var(--lightblue2);
}
.mapContainer{
  height:540px; 
  background-color: var(--lightblue2);
  border-radius: 20px;  
}
.map-container{
  background-color: #ddd;
  position: relative;
}
.show-all-photos{
  position: absolute;
  bottom: 10px;
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 8px;
  margin: auto;
  font-size: 13px;
  right: 52px;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sticky{
  position: sticky;
  top: -26px;
  left: 0px;
  z-index: 9999;
  background-color: white;
  padding:5px 0px;
  box-shadow: -29px 23px 20px -27px #444;
}