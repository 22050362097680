
.searchBarInner{
    min-height: 120px;
    width: 100%;
    background-color:var(--lightblue2);
    padding: 15px;
}
.developer-section{
    border-radius: 30px;
    background-color: var(--light-opacity70);
}
.searchBarInner2{
    min-height: 50px;
    width: 100%;
    background-color:white;
    border-radius: 30px;
    border: solid 1px var(--lightblue);
}
.searchInput{
    padding: 5px 20px;
    overflow: hidden;
    outline: 0px;
    border:solid 1px var(--lightblue);
    border-radius: 60px;
    color: var(--blue);
    font-size: 14px;
}
.multi-range-slider {
background-color: transparent !important;
border: solid 0px gray !important;
border-radius: 0px !important;
padding: 0px !important;
box-shadow:none !important;
}

.multi-range-slider .ruler, .labels{
    display: none !important;
}
.multi-range-slider .bar-inner{
    background-color: var(--blue) !important;
}
.multi-range-slider  .thumb{
    margin-top: 2px !important;
}
.rangelable{
    font-size: 14px;
    margin-left: -8px;
}