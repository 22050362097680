.dropdownMain{
    position: relative;
    z-index: 99999999999 !important;
}
.dropdownContainer{
border-radius: 10px;
position: absolute;
z-index: 99999999999 !important;
background-color: white;
display:inline-block;
overflow: hidden;
max-height: 310px;
min-width: 100px;
position: absolute;
inset: 0px 0px auto auto;
margin: 0px;
transform: translate3d(2.5px, 30px, 0px);
box-shadow: 0 .25rem .625rem rgba(20, 20, 20, .1);
inset-block-start: -3px !important;
}
.dropdown-divider{
    height: 0;
    margin: 0;
    border-top: 1px solid rgba(0,0,0,0.17);
    opacity: 1;
}
.dropdownContainer > ul{
overflow: scroll;
max-height: 310px;
padding-left: 0;
list-style: none;
}
.dropdownItem{
padding: 10px 5px 0px 0px;
list-style: none;
}
.dropdownItem a{
 text-decoration: none;
 font-size: 12px;
}
.header-notification-text{
    color: #8c9097;
    font-size: .75rem;
    font-weight: 400 !important;
}
.close-btn{
    margin: 0px;
    padding: 0px;
    width: 25px;
    float: right;
    position: absolute;
    right: 3px;
}