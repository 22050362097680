.zoomWrap{
    width: 50%;
    position: absolute;
    bottom: 0px;
    padding: 5px 15px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.zoomWrap input{
    width: 100%;  
}
.editorContainer{
display:inline-flex;
width: 100%;
}
.editorToolContainer{
    width: 250px;
    height: 100%;
    overflow: scroll;
}
.editorToolContainer input[type = "range"]{
    width: 100%;
    cursor: pointer;
}
.editorWrapper{
    width: 100%;
    height: 400px !important;
    background-color: rgb(201, 201, 201);
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
}
.canvasEditor{
    width: 100%;
    height: 400px !important; 
}

.editorCanvas{
    width: 100%;
    height: 100%;
}