.footer{
    min-height: 400px;
    background-color: var(--blue);
}
.socialIcon{
    width:60px;
    margin: 5px;
}
.newsletterContainer{
    display: inline;
}
.newsletterInput{
    border-radius: 50px 0px 0px 50px;
    height: 40px;
    border:solid #ffffff 2px;
    outline: 0px;
    padding:5px 16px;
    width:70%;
}
.newsletterButton{
    border-radius: 0px 50px 50px 0px;
    border:solid #ffffff 2px;
    outline: 0px;
    height: 40px;
    padding:5px 20px;
    font-size: 14px;
    background-color: var(--lightblue);
}
.footerLink{
    text-decoration: none;
    list-style: none;
    font-weight: bold;
    margin-top: 20px;
}
.footerLink .nav-link{
    padding: 10px 0px;
    color:#ffffff;
}